import React from "react"
import Links from "../../components/links"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/section"
import Mail from "../../components/mail"

const links = [
  {
    title: "1. Hair Reduction",
    to: "/info/vpl-light-treatment",
  },
  { title: "2. Anti Aging", to: "/info/vpl-light-treatment/anti-aging" },
  {
    title: "3. Pigmentations Removal",
    to: "/info/vpl-light-treatment/pigmentations-removal",
  },
  {
    title: "4. Vancé",
    to: "/info/vpl-light-treatment/vance",
  },
  {
    title: "5. Couperose - rostra",
    to: "/info/vpl-light-treatment/couperose-rostra",
  },
  {
    title: "6. Acné",
    to: "/info/vpl-light-treatment/acne",
  },
]

const Info = () => (
  <Layout>
    <SEO title="Info" />

    <Section>
      <article className="mb-8 prose lg:prose-xl">
        <h1>Info</h1>

        <p>
          Klik op onderstaande links om meer te weten over een bepaald
          onderwerp. Is er iets onduidelijk of wil je meer weten over iets?{" "}
          Stuur ons een mail voor meer informatie: <Mail />
        </p>
      </article>

      <Links data={links} />
    </Section>
  </Layout>
)

export default Info
